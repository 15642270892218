import Home from "./home";
import News from "./news";
import Portal from "./portal";
import Signin from "./signin";
import Contact from "./contact";
import Rewards from "./rewards";
import Rankings from "./rankings";
import NotFound from "./not-found";
import Challenges from "./challenges";
import NewsDetail from "./news-detail";
import ChallengeRanking from "./challenges-ranking";

export {
  Home,
  News,
  Portal,
  Signin,
  Contact,
  Rewards,
  Rankings,
  NotFound,
  Challenges,
  NewsDetail,
  ChallengeRanking,
};
