import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import styles from "./index.module.scss";
import { Layout } from "../../components";
import { AppContext } from "../../contexts";
import { getNews } from "../../services/news";

const News = () => {
  const navigate = useNavigate();
  const { setIsGlobalLoading } = useContext(AppContext);

  const optionsNews = {
    queryKey: ["news"],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) => getNews(pageParam),
    getNextPageParam: (_lastPage: any, pages: any) => {
      if (pages.length < pages[0]?.itemsCount / 9) {
        return pages.length + 1;
      }

      return undefined;
    },
  };

  const {
    hasNextPage,
    fetchNextPage,
    data: listNews,
    isPending: isNewsPending,
  } = useInfiniteQuery(optionsNews);

  useEffect(() => {
    setIsGlobalLoading(isNewsPending);
  }, [isNewsPending, setIsGlobalLoading]);

  return (
    <Layout>
      <div className={styles["news-wrapper"]}>
        {/* Spotlight */}
        <div className={styles["news-wrapper__hero"]}>
          <div className={styles["hero__content"]}>
            <div className={styles["content_title"]}>
              <span>{listNews?.pages[0]?.items[0]?.title}</span>
            </div>
            <div className={styles["content_description"]}>
              <span>{listNews?.pages[0]?.items[0]?.short_description}</span>
              <div
                className={styles["description__button"]}
                onClick={() =>
                  navigate(`/news-detail/${listNews?.pages[0]?.items[0]?.slug}`)
                }
              >
                <span>Xem chi tiết</span>
              </div>
            </div>
            <img
              alt=""
              src="/images/mars.gif"
              className={styles["content__image--1"]}
            />
            <img
              alt=""
              src="/images/mercury.gif"
              className={styles["content__image--2"]}
            />
          </div>
          <div className={styles["hero__image"]}>
            <div className={styles["hero__image--main"]}>
              <svg viewBox="0 0 766 700">
                <mask fill="white" id="path-1-inside-1_687_6384">
                  <path d="M0 32C0 14.3269 14.3269 0 32 0H634C651.673 0 666 14.3269 666 32V68C666 85.6731 680.327 100 698 100H734C751.673 100 766 114.327 766 132V668C766 685.673 751.673 700 734 700L132 700C114.327 700 100 685.673 100 668V374C100 356.327 85.6731 342 68 342H32C14.3269 342 0 327.673 0 310V32Z" />
                </mask>
                <path
                  fill="url(#pattern0_687_6384)"
                  d="M0 32C0 14.3269 14.3269 0 32 0H634C651.673 0 666 14.3269 666 32V68C666 85.6731 680.327 100 698 100H734C751.673 100 766 114.327 766 132V668C766 685.673 751.673 700 734 700L132 700C114.327 700 100 685.673 100 668V374C100 356.327 85.6731 342 68 342H32C14.3269 342 0 327.673 0 310V32Z"
                />
                <path
                  fill="white"
                  fillOpacity="0.5"
                  mask="url(#path-1-inside-1_687_6384)"
                  d="M132 700L132 696L132 700ZM734 700L734 704L734 700ZM634 -4H32V4H634V-4ZM670 68V32H662V68H670ZM734 96H698V104H734V96ZM770 668V132H762V668H770ZM132 704L734 704L734 696L132 696L132 704ZM96 374V668H104V374H96ZM32 346H68V338H32V346ZM-4 32V310H4V32H-4ZM32 338C16.536 338 4 325.464 4 310H-4C-4 329.882 12.1177 346 32 346V338ZM104 374C104 354.118 87.8823 338 68 338V346C83.464 346 96 358.536 96 374H104ZM132 696C116.536 696 104 683.464 104 668H96C96 687.882 112.118 704 132 704L132 696ZM762 668C762 683.464 749.464 696 734 696L734 704C753.882 704 770 687.882 770 668H762ZM734 104C749.464 104 762 116.536 762 132H770C770 112.118 753.882 96 734 96V104ZM662 68C662 87.8823 678.118 104 698 104V96C682.536 96 670 83.464 670 68H662ZM32 -4C12.1177 -4 -4 12.1178 -4 32H4C4 16.536 16.536 4 32 4V-4ZM634 4C649.464 4 662 16.536 662 32H670C670 12.1177 653.882 -4 634 -4V4Z"
                />
                <defs>
                  <pattern
                    width="1"
                    height="1"
                    id="pattern0_687_6384"
                    patternContentUnits="objectBoundingBox"
                  >
                    <use
                      xlinkHref="#image0_687_6384"
                      transform="matrix(0.000669478 0 0 0.000732601 -0.185546 0)"
                    />
                  </pattern>
                  <image
                    width="2048"
                    height="1365"
                    id="image0_687_6384"
                    xlinkHref={listNews?.pages[0]?.items[0]?.thumbnail}
                  />
                </defs>
              </svg>
            </div>
            <img
              alt=""
              src="/images/earth.gif"
              className={styles["hero__image--top-right-corner"]}
            />
            <img
              alt=""
              src="/images/hero-bottom-leftcorner-mask.svg"
              className={styles["hero__image--bottom-left-corner"]}
            />
          </div>
        </div>
        {/* Spotlight */}
        {/* News list */}
        <div className={styles["news-wrapper__list"]}>
          <div className={styles["list__box"]}>
            <div className={styles["box__title"]}>
              <span>Tin tức</span>
            </div>
            <div className={styles["box__content"]}>
              {listNews?.pages.map((group, i) => (
                <Fragment key={i}>
                  {group.items.map((item: any) => (
                    <div
                      key={item.id}
                      className={styles["content__item"]}
                      onClick={() => navigate(`/news-detail/${item.slug}`)}
                    >
                      <div className={styles["item__image"]}>
                        <svg viewBox="0 0 450 411">
                          <path
                            fill={`url(#pattern0_6137_3605_${item.id})`}
                            d="M377.029 7.02943C372.529 2.52856 366.424 0 360.059 0H24C10.7452 0 0 10.7452 0 24V387C0 400.255 10.7452 411 24 411H426C439.255 411 450 400.255 450 387V89.9411C450 83.5759 447.471 77.4714 442.971 72.9706L377.029 7.02943Z"
                          />
                          <path
                            opacity="0.7"
                            fill="url(#paint0_linear_6137_3605)"
                            d="M398.333 75H445L375 5V51.6667C375 64.5533 385.447 75 398.333 75Z"
                          />
                          <defs>
                            <pattern
                              width="1"
                              height="1"
                              id={`pattern0_6137_3605_${item.id}`}
                              patternContentUnits="objectBoundingBox"
                            >
                              <use
                                xlinkHref={`#image0_6137_3605_${item.id}`}
                                transform="matrix(0.00174968 0 0 0.00191571 -0.108889 0)"
                              />
                            </pattern>
                            <linearGradient
                              x1="445"
                              y1="104.167"
                              x2="349.784"
                              y2="81.4299"
                              id="paint0_linear_6137_3605"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#28CBF9" />
                              <stop offset="1" stop-color="#B326FE" />
                            </linearGradient>
                            <image
                              width="696"
                              height="522"
                              xlinkHref={item.thumbnail}
                              id={`image0_6137_3605_${item.id}`}
                            />
                          </defs>
                        </svg>
                      </div>
                      <div className={styles["item__title"]}>
                        <span>{item.title}</span>
                      </div>
                      <div className={styles["item__info"]}>
                        <span>
                          {moment(item.lastUpdate).format("DD.MM.YYYY")}
                        </span>
                        <div className={styles["info_view"]}>
                          <img alt="" src="/images/eye-icon.svg" />
                          <span>{item.total_view} lượt xem</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
            {hasNextPage && (
              <div
                onClick={() => fetchNextPage()}
                className={styles["box__expand"]}
              >
                <span>Xem thêm</span>
                <img alt="" src="/images/double-arrow-down.svg" />
              </div>
            )}
          </div>
        </div>
        {/* News list */}
      </div>
    </Layout>
  );
};

export default News;
