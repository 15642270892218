import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import styles from "./index.module.scss";
import Loading from "../../components/Loading";
import { getToken, getSigninURL } from "../../services/auth";

const Signin = () => {
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const handleClickSignin = async () => {
    setIsLoading(true);
    const res = await getSigninURL();

    if (res?.login_web) window.location.href = res.login_web;
  };

  useEffect(() => {
    (async () => {
      const acheckinToken = searchParams.get("token");

      if (acheckinToken) {
        setIsLoading(true);

        const data = await getToken(acheckinToken).catch((err) => {
          setIsLoading(false);
          toast.error(err.message, { toastId: "signin-error" });
        });

        if (data?.accessToken && data?.refreshToken) {
          Cookies.set("accessTokenEmp", data.accessToken, { expires: 365 });
          Cookies.set("refreshTokenEmp", data.refreshToken, { expires: 365 });

          window.location.href = "/home";
        }
      }
    })();
  }, [searchParams]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles["signin-wrapper"]}>
      <div className={styles["signin-wrapper__header"]}>
        <img alt="" src={"/images/appota-inside-logo.svg"} />
        <div
          onClick={() => handleClickSignin()}
          className={styles["header__button"]}
        >
          <img alt="" src={"/images/signin-button.svg"} />
          <div className={styles["button__content"]}>
            <img alt="" src={"/images/email-icon.svg"} />
            <span>Đăng nhập</span>
          </div>
        </div>
      </div>
      <div className={styles["signin-wrapper__info"]}>
        <div className={styles["info__title"]}>
          <span>We are</span>
          <div className={styles["title__icon"]}>
            <img
              alt=""
              src={"/images/archer-boy.png"}
              className={styles["title__icon--boy"]}
            />
            <img
              alt=""
              src={"/images/archer-girl.png"}
              className={styles["title__icon--girl"]}
            />
          </div>
          <span>Appota</span>
        </div>
        <div className={styles["info__description"]}>
          Inside Appota là cổng thông tin nội bộ của Appota Group. Chỉ các thành
          viên có tài khoản email nội bộ của Appota hoặc tài khoản AD do bộ phận
          Nhân sự cung cấp mới có thể truy cập vào hệ thống này.
        </div>
      </div>
      <div className={styles["signin-wrapper__intro"]}>
        <div className={styles["intro-1"]}>
          <div className={styles["intro-2"]}>
            <video loop muted autoPlay>
              <source src="https://cdn.pixabay.com/video/2024/06/08/215769.mp4" />
            </video>
            {/* <div className={styles["intro-2--text"]}>
              <div>
                <span>SCROOL</span>
                <img alt="" src={"/images/arrow-down.svg"} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className={styles["signin-wrapper__background-gif"]}>
        <img alt="" src={"/images/mars.gif"} />
      </div>
    </div>
  );
};

export default Signin;
