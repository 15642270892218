import moment from "moment";
import { Link } from "react-router-dom";

import styles from "./index.module.scss";
import { putReadNotifications } from "../../services/notification";

enum NOTIFICATION_TYPE {
  "SYSTEM" = "SYSTEM",
  "PERSONAL" = "PERSONAL",
}

enum NOTIFICATION_CONTEXT {
  NEW_POST = "NEW_POST",
  REACH_POINT = "REACH_POINT",
  NEW_CHALLENGE = "NEW_CHALLENGE",
  VIEW_LEADER_BOARD = "VIEW_LEADER_BOARD",
}

enum NOTIFICATION_OBJECT_TYPE {
  POST = "POST",
  POINT = "POINT",
  CHALLENGE = "CHALLENGE",
}

interface NotificationSubject {
  id: string;
  name: string;
  image: string;
  createdAt: Date;
  updatedAt: Date;
  originId: string;
  notificationId: string;
  type: NOTIFICATION_OBJECT_TYPE;
}

interface NotificationDirect {
  id: string;
  name: string;
  image: string;
  createdAt: Date;
  updatedAt: Date;
  originId: string;
  urlId: string;
  notificationId: string;
  type: NOTIFICATION_OBJECT_TYPE;
}

export interface Notification {
  id: string;
  readAt: number;
  createdAt: string;
  updatedAt: string;
  employeeId: string;
  type: NOTIFICATION_TYPE;
  direct: NotificationDirect;
  context: NOTIFICATION_CONTEXT;
  subjects: Array<NotificationSubject>;
}

type Props = {
  openPoint: Function;
  notification: Notification;
  refetchNotifications: Function;
};

const NotificationItem = ({
  openPoint,
  notification,
  refetchNotifications,
}: Props) => {
  const renderDescription = () => {
    switch (notification.context) {
      case NOTIFICATION_CONTEXT.NEW_POST:
        return (
          <span>
            <strong>ANews</strong> có tin tức mới. Đừng bỏ lỡ!
          </span>
        );
      case NOTIFICATION_CONTEXT.NEW_CHALLENGE:
        return (
          <span>
            <strong>AChallenge</strong> có thử thách mới. Tham gia ngay!
          </span>
        );
      case NOTIFICATION_CONTEXT.REACH_POINT:
        return (
          <span>
            Chúc mừng bạn đẫ đạt mốc <strong>10,000</strong> điểm.
          </span>
        );
      case NOTIFICATION_CONTEXT.VIEW_LEADER_BOARD:
        return (
          <span>
            Bảng xếp hạng năm {notification.subjects[0]?.name}. Cùng nhìn lại
            nào!
          </span>
        );
      default:
        return <></>;
    }
  };

  const onClickNotification = async () => {
    if (notification.readAt) {
      return;
    }

    const result = await putReadNotifications({ id: notification.id });

    if (result) refetchNotifications();
  };

  const renderAction = () => {
    switch (notification.direct?.type) {
      case NOTIFICATION_OBJECT_TYPE.POST:
        return (
          <Link
            to={`/news-detail/${notification.direct?.urlId}`}
            className={styles["detail__action"]}
          >
            Xem ngay
          </Link>
        );
      case NOTIFICATION_OBJECT_TYPE.CHALLENGE:
        return (
          <Link to={`/challenges`} className={styles["detail__action"]}>
            Xem chi tiết
          </Link>
        );
      case NOTIFICATION_OBJECT_TYPE.POINT:
        return (
          <div onClick={() => openPoint()} className={styles["detail__action"]}>
            Đổi điểm ngay
          </div>
        );
      default:
        return <div className={styles["detail__action"]}>Xem chi tiết</div>;
    }
  };

  return (
    <div className={styles["tab__item"]}>
      <img
        alt=""
        className={styles["item__avatar"]}
        src={
          notification.type === NOTIFICATION_TYPE.SYSTEM
            ? "/images/appota-circle-logo.svg"
            : notification?.direct?.image
        }
      />
      <div className={styles["item__detail"]}>
        <div>
          <span className={styles["detail__title"]}>
            {notification.type === NOTIFICATION_TYPE.SYSTEM
              ? "Appota Thông báo"
              : ``}
          </span>
          <div className={styles["detail__des"]}>{renderDescription()}</div>
        </div>
        <div
          className={styles["detail__action__wrapper"]}
          onClick={() => onClickNotification()}
        >
          {renderAction()}
        </div>
        <span className={styles["detail__time"]}>
          {moment(notification.createdAt).locale("vi").fromNow()}
        </span>
      </div>
      {notification.readAt ? null : (
        <div className={styles["item__mark-read"]} />
      )}
    </div>
  );
};

export default NotificationItem;
