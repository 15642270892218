import { useQuery } from "@tanstack/react-query";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import LuckySpin from "../LuckySpin";
import styles from "./index.module.scss";
import { EmployeeModal } from "../Modals";
import { AppContext } from "../../contexts";
import Congratulations from "../Congratulations";
import { getProfile } from "../../services/account";
import NotificationPopup from "../NotificationPopup";
import { NOTIFCATION_ACTION } from "../../contexts/notification";
import { getUnreadNotificationCount } from "../../services/notification";

const Header = () => {
  const {
    accountState,
    accountDispatch,
    notificationState,
    notificationDispatch,
  } = useContext(AppContext);
  const { pathname } = useLocation();

  const [isLuckySpin, setLuckySpin] = useState(true);
  const [isOpenNotiPopup, setIsOpenNotiPopup] = useState(false);
  const [isCongratulation, setIsCongratulation] = useState(false);
  const [isOpenEmployeeModal, setIsOpenEmployeeModal] = useState(false);

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(),
  });

  const { data: totalUnreadNotification } = useQuery({
    queryKey: ["total-unread-notification"],
    queryFn: () => getUnreadNotificationCount(),
  });

  useEffect(() => {
    profile && accountDispatch({ payload: profile, type: "S-ACCOUNT" });

    totalUnreadNotification &&
      notificationDispatch({
        type: NOTIFCATION_ACTION.UPDATE_UNREAD,
        payload: { totalUnread: totalUnreadNotification.total },
      });
  }, [profile, accountDispatch, totalUnreadNotification, notificationDispatch]);

  return (
    <div className={styles["header-wrapper"]}>
      <div className={styles["header-wrapper__points"]}>
        <div className={styles["points__left-side"]}>
          <div className={styles["left-side__icon"]}>
            <img alt="" src={"/images/medal.svg"} />
          </div>
          <div className={styles["left-side__detail"]}>
            <span>Điểm tích lũy: {accountState.total_points}</span>
            <div className={styles["detail__progress"]}>
              {/* {Array.from(Array(Math.floor(percent / 10)).keys()).map(
                (item) => (
                  <div
                    key={item + 1}
                    className={styles["detail__progress--active"]}
                    style={{
                      borderTopLeftRadius: `${item === 0 ? 6 : 0}px`,
                      borderTopRightRadius: `${item === 9 ? 6 : 0}px`,
                      borderBottomLeftRadius: `${item === 0 ? 6 : 0}px`,
                      borderBottomRightRadius: `${item === 9 ? 6 : 0}px`,
                    }}
                  />
                )
              )} */}
              {/* Temp */}
              <div
                style={{
                  width: `${
                    accountState.total_points > 10000
                      ? 100
                      : accountState.total_points / 100
                  }%`,
                }}
                className={styles["detail__progress--active"]}
              />
              {/* Temp */}
            </div>
            {/* Temp */}
            <span>10,000</span>
            {/* Temp */}
          </div>
        </div>
        {accountState.total_points > 9999 ? (
          <div
            className={styles["points__right-side"]}
            onClick={() => setIsCongratulation(true)}
          >
            <span>Đổi điểm lấy quà</span>
          </div>
        ) : null}
      </div>
      <div className={styles["header-wrapper__menu"]}>
        <img alt="" src={"/images/appota-inside-logo.svg"} />
        <div className={styles["menu_middle"]}>
          {[
            { pathname: "/home", text: "Trang chủ" },
            { text: "ANews", pathname: "/news" },
            // { text: "AChallenge", pathname: "/challenges" },
            { text: "ARewards", pathname: "/rewards" },
            { text: "APortal", pathname: "/portal" },
            { text: "Liên hệ", pathname: "/contact" },
          ].map((item) => (
            <Link
              to={item.pathname}
              key={item.pathname}
              className={
                pathname.includes(item.pathname)
                  ? styles["menu_middle--active"]
                  : ""
              }
            >
              {item.text}
            </Link>
          ))}
        </div>
        <div className={styles["menu_right"]}>
          <div className={styles["menu_right__notification"]}>
            <div
              className={styles["notification__icon"]}
              onClick={() => setIsOpenNotiPopup(!isOpenNotiPopup)}
            >
              <img alt="" src={"/images/ring.svg"} />
              {!!notificationState.totalUnread && (
                <div className={styles["unread__decorator"]}>
                  <div className={styles["unread__inner"]}></div>
                </div>
              )}
            </div>
            <NotificationPopup
              isOpen={isOpenNotiPopup}
              openPointPopup={() => setIsCongratulation(true)}
            />
          </div>
          <div
            className={styles["menu_right__account"]}
            onClick={() => setIsOpenEmployeeModal(true)}
          >
            <div className={styles["account_avatar"]}>
              <img alt="" src={accountState.avatar} />
            </div>
            <div className={styles["account_name"]}>
              Xin chào, {accountState.name.split(" ")[2]}
            </div>
            <div className={styles["account_expand"]}>
              <img alt="" src={"/images/arrow-down-1.svg"} />
            </div>
          </div>
        </div>
      </div>
      <Congratulations
        isOpen={isCongratulation}
        onClose={() => setIsCongratulation(false)}
      />
      <EmployeeModal
        isOpen={isOpenEmployeeModal}
        onClose={() => setIsOpenEmployeeModal(false)}
      />
      {accountState.total_points === 10000 ? (
        <LuckySpin isOpen={isLuckySpin} onClose={() => setLuckySpin(false)} />
      ) : null}
    </div>
  );
};

export default Header;
