import moment from "moment";

import styles from "./index.module.scss";

type Props = {
  comment: any;
  children?: any;
  isChild?: boolean;
  commentSelected?: any;
  childCommentsCreated?: any;
  setCommentSelected?: (commentSelected: any) => void;
};

const Comment = ({
  comment,
  children,
  commentSelected,
  isChild = false,
  setCommentSelected,
  childCommentsCreated,
}: Props) => {
  return (
    <div
      className={`${styles["comment-wrapper"]} ${
        isChild ? styles["child-comment-wrapper"] : ""
      }`}
    >
      <div className={styles["comment-wrapper__owner"]}>
        <img alt="" src={comment.avatar} className={styles["owner__avatar"]} />
        <div className={styles["owner-info__wrapper"]}>
          <div className={styles["owner__info"]}>
            <span className={styles["info__name"]}>{comment.name}</span>
            <span className={styles["comment__created"]}>
              {moment(comment.actionTime).locale("vi").fromNow()}
            </span>
          </div>
          {/* <img
            alt=""
            src="/images/3-dots-icon.svg"
            className={styles["owner__action"]}
          /> */}
        </div>
      </div>
      <div className={styles["comment-wrapper__content"]}>
        <span className={styles["content__text"]}>{comment.content}</span>
        <div className={styles["content__action"]}>
          <div className={styles["action__wrapper"]}>
            {isChild ? null : (
              <div
                className={styles["action__reply"]}
                onClick={() =>
                  setCommentSelected && setCommentSelected(comment)
                }
              >
                <img
                  alt=""
                  src="/images/reply-icon.svg"
                  className={styles["reply__icon"]}
                />
                <span>
                  Trả lời (
                  {(parseInt(comment.total_child) || 0) +
                    childCommentsCreated.filter(
                      (item: any) => comment.id === item.parent_id
                    ).length}
                  )
                </span>
              </div>
            )}
            {/* <div className={styles["action__react"]}>
              <img
                alt=""
                className={styles["react__icon"]}
                src="/images/emoji-outline-icon.svg"
              />
              <span>Biểu cảm</span>
            </div> */}
          </div>
          {/* <div className={styles["action__emoji"]}>
            <img
              alt=""
              src="/images/heart-emoji.svg"
              className={`${styles["emoji__item"]} ${styles["emoji__item--active"]}`}
            />
            <img
              alt=""
              src="/images/haha-emoji.svg"
              className={styles["emoji__item"]}
            />
            <img
              alt=""
              src="/images/wow-emoji.svg"
              className={styles["emoji__item"]}
            />
            <img
              alt=""
              src="/images/sad-emoji.svg"
              className={styles["emoji__item"]}
            />
          </div> */}
        </div>
        {!isChild && commentSelected && comment.id === commentSelected.id ? (
          <div>{children}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Comment;
