import { useState, useEffect, useContext } from "react";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";

import styles from "./index.module.scss";
import { Layout } from "../../components";
import { AppContext } from "../../contexts";
import { getCompanies } from "../../services/portal";
import { getLeaderboard } from "../../services/challenge";

const Rankings = () => {
  const { setIsGlobalLoading } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({ name: "", unit: "" });

  const { data: companies, isPending: isCompaniesPending } = useQuery({
    queryKey: ["companies"],
    queryFn: () => getCompanies(),
  });

  const optionsLeaderboard = {
    initialPageParam: 1,
    queryKey: ["rankings-leaderboard"],
    getNextPageParam: (lastPage: any) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    queryFn: ({ pageParam = 1 }) => {
      const filters: { company_id?: string; employee_name?: string } = {};

      if (query.unit) filters.company_id = query.unit;
      if (query.name) filters.employee_name = query.name;

      return getLeaderboard({ page: pageParam, ...filters });
    },
  };
  const {
    fetchNextPage,
    data: leaderboard,
    refetch: refetchLeaderboard,
    isPending: isLeaderboardPending,
  } = useInfiniteQuery(optionsLeaderboard);

  const handleClickSearch = () => {
    setIsLoading(true);

    refetchLeaderboard().then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsGlobalLoading(isCompaniesPending || isLeaderboardPending);
  }, [isCompaniesPending, setIsGlobalLoading, isLeaderboardPending]);

  return (
    <Layout>
      <div className={styles["rankings-wrapper"]}>
        <div className={styles["rankings-wrapper__content"]}>
          <span className={styles["content__title"]}>
            Bảng xếp hạng {new Date().getFullYear()}
          </span>
          <div className={styles["content__filter"]}>
            <label className={styles["filter__field--input"]}>
              <img alt="" src="/images/magnifying-glass-icon.svg" />
              <input
                value={query.name}
                placeholder="Tìm kiếm nhân viên"
                onChange={(e) => setQuery({ ...query, name: e.target.value })}
              />
            </label>
            <div className={styles["filter__field--select"]}>
              <div className={styles["select__line"]} />
              <select
                value={query.unit}
                onChange={(e) => setQuery({ ...query, unit: e.target.value })}
              >
                <option hidden value="" disabled>
                  Đơn vị công tác
                </option>
                {companies?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              disabled={isLoading}
              onClick={() => handleClickSearch()}
              className={styles["filter__field--button"]}
            >
              Tìm kiếm
            </button>
          </div>
          <div className={styles["content__rankings"]}>
            {leaderboard?.pages
              .reduce((acc, cur) => [...acc, ...cur.data], [])
              .map((item: any) => (
                <EmployeeRanking
                  rank={item.index}
                  key={item.employee_id}
                  name={item.employee_name}
                  point={item.total_points}
                  avatar={item.employee_avatar}
                  position={item.employee_position}
                  department={item.employee_department}
                />
              ))}
          </div>
          {leaderboard?.pages[leaderboard?.pages.length - 1].hasNextPage ? (
            <div
              onClick={() => fetchNextPage()}
              className={styles["content__expand"]}
            >
              <span>Xem thêm</span>
              <img alt="" src="/images/double-arrow-down-1.svg" />
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

const EmployeeRanking = ({
  name,
  rank,
  point,
  avatar,
  position,
  department,
}: {
  name: string;
  rank: number;
  point: number;
  avatar: string;
  position: string;
  department: string;
}) => {
  return (
    <div className={styles["employee-ranking-wrapper"]}>
      <div className={styles["employee-ranking-wrapper__main"]}>
        <img alt="" src={avatar} className={styles["main__mask"]} />
        <svg width="0" height="0" fill="none">
          <clipPath id="employee-mask">
            <path d="M56 24C56 10.7452 66.7452 0 80 0H244C257.255 0 268 10.7452 268 24V282C268 295.255 257.255 306 244 306H182.833C173.742 306 165.432 311.136 161.367 319.267L154.633 332.733C150.568 340.864 142.258 346 133.167 346H24C10.7452 346 0 335.255 0 322V80C0 66.7452 10.7452 56 24 56H32C45.2548 56 56 45.2548 56 32V24Z" />
          </clipPath>
        </svg>
        <div className={styles["main__rank"]}>{rank}</div>
        <div
          {...(rank > 3
            ? {
                style: {
                  backgroundImage:
                    "linear-gradient(to right, #E2FF6C, #00FFFF)",
                },
              }
            : {})}
          className={styles["main__point"]}
        >
          {point}pts
        </div>
      </div>
      <div className={styles["employee-ranking-wrapper__info"]}>
        <span className={styles["info__name"]}>{name}</span>
        <span className={styles["info__position"]}>
          {position} - {department}
        </span>
      </div>
    </div>
  );
};

export default Rankings;
