// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer-wrapper__zR0TO {
  width: 100%;
  height: 104px;
  font-size: 14px;
  margin-top: 72px;
  color: #ffffff;
  line-height: 104px;
  text-align: center;
  background: linear-gradient(rgba(19, 36, 128, 0), rgb(19, 36, 128));
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,mEAAA;AACF","sourcesContent":[".footer-wrapper {\n  width: 100%;\n  height: 104px;\n  font-size: 14px;\n  margin-top: 72px;\n  color: #ffffff;\n  line-height: 104px;\n  text-align: center;\n  background: linear-gradient(rgba(19, 36, 128, 0), rgba(19, 36, 128, 1));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-wrapper": `Footer_footer-wrapper__zR0TO`
};
export default ___CSS_LOADER_EXPORT___;
