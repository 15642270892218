import moment from "moment";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.scss";
import { Layout } from "../../components";
import { AppContext } from "../../contexts";

const Challenges = () => {
  const navigate = useNavigate();

  const { accountState } = useContext(AppContext);

  const [searchValue, setSearchValue] = useState("");

  console.log(searchValue);

  return (
    <Layout>
      <div className={styles["challenges-wrapper"]}>
        <div className={styles["challenges-wrapper__content"]}>
          <span className={styles["content__title"]}>Challenge</span>
          <div className={styles["content__box"]}>
            <div className={styles["box__left"]}>
              <img
                alt=""
                className={styles["left__background"]}
                src="/images/challenge-background.svg"
              />
              <div className={styles["left__content"]}>
                <span className={styles["content__list-title"]}>
                  Danh sách challenge
                </span>
                <div style={{ flex: 1, height: "100%" }}>
                  <div
                    style={{
                      width: "86%",
                      display: "flex",
                      borderRadius: "28px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <img
                      alt=""
                      style={{ marginLeft: "20px" }}
                      src="/images/magnifying-glass-icon.svg"
                    />
                    <input
                      style={{
                        width: "100%",
                        border: "none",
                        height: "24px",
                        padding: "10px",
                        outline: "none",
                        fontSize: "14px",
                        color: "#aaaaaa",
                        borderRadius: "28px",
                        backgroundColor: "#f5f5f5",
                      }}
                      placeholder="Tìm kiếm sự kiện"
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                  </div>
                  <div className={styles["challenges__list"]}>
                    {[
                      {
                        id: "01HZGGFHCNGNR8E8538K24ZS62",
                        endTime: "2024-06-04T02:37:17.007Z",
                        startTime: "2024-06-04T02:37:17.007Z",
                        title: "Hình ảnh đẹp giải đấu APPOTA CUP 2024",
                        thumbnail:
                          "https://s3-alpha-sig.figma.com/img/f958/49f6/bee8f538ce09aeebcef71a0b8bae0feb?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h0hSVbUpwg0WnhZKYT-50fdYft4meKuBfGsNA9ZpXT4wDcb~EgUmQcIiJqtOHbidzHVA4yHs~i9wRkCrwU5J5uK3V8I60-TdYvc41jYfPfPjqYZlSLxH7OgXiAneuqaATm24aSyeiN-LlYjoifmFKS3PhkdLBNHiU1YidjAEeItHoJnER0s2K47wGhMUXy7z2E7TjbOo9NYoSfhyBMzE5qaY8OcD0cydK8q63nQXVKYZVc11oM4HxoCNsMHxM0nJPPDoUE3qSxkUe4mK6x6tYR01lZyfs70XxBzTozNCtufAvDNa5Sl6Q5eSmkZT3LeJcfGeuYEjA~Yd548-3mpwjA__",
                      },
                      {
                        id: "02HZGGFHCNGNR8E8538K24ZS62",
                        endTime: "2024-06-04T02:37:17.007Z",
                        startTime: "2024-06-04T02:37:17.007Z",
                        title: "Hình ảnh đẹp giải đấu APPOTA CUP 2024",
                        thumbnail:
                          "https://s3-alpha-sig.figma.com/img/f958/49f6/bee8f538ce09aeebcef71a0b8bae0feb?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h0hSVbUpwg0WnhZKYT-50fdYft4meKuBfGsNA9ZpXT4wDcb~EgUmQcIiJqtOHbidzHVA4yHs~i9wRkCrwU5J5uK3V8I60-TdYvc41jYfPfPjqYZlSLxH7OgXiAneuqaATm24aSyeiN-LlYjoifmFKS3PhkdLBNHiU1YidjAEeItHoJnER0s2K47wGhMUXy7z2E7TjbOo9NYoSfhyBMzE5qaY8OcD0cydK8q63nQXVKYZVc11oM4HxoCNsMHxM0nJPPDoUE3qSxkUe4mK6x6tYR01lZyfs70XxBzTozNCtufAvDNa5Sl6Q5eSmkZT3LeJcfGeuYEjA~Yd548-3mpwjA__",
                      },
                      {
                        id: "03HZGGFHCNGNR8E8538K24ZS62",
                        endTime: "2024-06-04T02:37:17.007Z",
                        startTime: "2024-06-04T02:37:17.007Z",
                        title: "Hình ảnh đẹp giải đấu APPOTA CUP 2024",
                        thumbnail:
                          "https://s3-alpha-sig.figma.com/img/f958/49f6/bee8f538ce09aeebcef71a0b8bae0feb?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h0hSVbUpwg0WnhZKYT-50fdYft4meKuBfGsNA9ZpXT4wDcb~EgUmQcIiJqtOHbidzHVA4yHs~i9wRkCrwU5J5uK3V8I60-TdYvc41jYfPfPjqYZlSLxH7OgXiAneuqaATm24aSyeiN-LlYjoifmFKS3PhkdLBNHiU1YidjAEeItHoJnER0s2K47wGhMUXy7z2E7TjbOo9NYoSfhyBMzE5qaY8OcD0cydK8q63nQXVKYZVc11oM4HxoCNsMHxM0nJPPDoUE3qSxkUe4mK6x6tYR01lZyfs70XxBzTozNCtufAvDNa5Sl6Q5eSmkZT3LeJcfGeuYEjA~Yd548-3mpwjA__",
                      },
                      {
                        id: "04HZGGFHCNGNR8E8538K24ZS62",
                        endTime: "2024-06-04T02:37:17.007Z",
                        startTime: "2024-06-04T02:37:17.007Z",
                        title: "Hình ảnh đẹp giải đấu APPOTA CUP 2024",
                        thumbnail:
                          "https://s3-alpha-sig.figma.com/img/f958/49f6/bee8f538ce09aeebcef71a0b8bae0feb?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h0hSVbUpwg0WnhZKYT-50fdYft4meKuBfGsNA9ZpXT4wDcb~EgUmQcIiJqtOHbidzHVA4yHs~i9wRkCrwU5J5uK3V8I60-TdYvc41jYfPfPjqYZlSLxH7OgXiAneuqaATm24aSyeiN-LlYjoifmFKS3PhkdLBNHiU1YidjAEeItHoJnER0s2K47wGhMUXy7z2E7TjbOo9NYoSfhyBMzE5qaY8OcD0cydK8q63nQXVKYZVc11oM4HxoCNsMHxM0nJPPDoUE3qSxkUe4mK6x6tYR01lZyfs70XxBzTozNCtufAvDNa5Sl6Q5eSmkZT3LeJcfGeuYEjA~Yd548-3mpwjA__",
                      },
                      {
                        id: "05HZGGFHCNGNR8E8538K24ZS62",
                        endTime: "2024-06-04T02:37:17.007Z",
                        startTime: "2024-06-04T02:37:17.007Z",
                        title: "Hình ảnh đẹp giải đấu APPOTA CUP 2024",
                        thumbnail:
                          "https://s3-alpha-sig.figma.com/img/f958/49f6/bee8f538ce09aeebcef71a0b8bae0feb?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=h0hSVbUpwg0WnhZKYT-50fdYft4meKuBfGsNA9ZpXT4wDcb~EgUmQcIiJqtOHbidzHVA4yHs~i9wRkCrwU5J5uK3V8I60-TdYvc41jYfPfPjqYZlSLxH7OgXiAneuqaATm24aSyeiN-LlYjoifmFKS3PhkdLBNHiU1YidjAEeItHoJnER0s2K47wGhMUXy7z2E7TjbOo9NYoSfhyBMzE5qaY8OcD0cydK8q63nQXVKYZVc11oM4HxoCNsMHxM0nJPPDoUE3qSxkUe4mK6x6tYR01lZyfs70XxBzTozNCtufAvDNa5Sl6Q5eSmkZT3LeJcfGeuYEjA~Yd548-3mpwjA__",
                      },
                    ].map((item) => (
                      <div
                        key={item.id}
                        style={{ display: "flex", marginBottom: "24px" }}
                      >
                        <div
                          style={{
                            height: "124px",
                            width: "27.394%",
                            position: "relative",
                            maskRepeat: "no-repeat",
                            mask: "url('/images/news-mask.svg')",
                          }}
                        >
                          <img
                            alt=""
                            src={item.thumbnail}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              aspectRatio: "220 / 124",
                            }}
                          />
                          <img
                            alt=""
                            src="/images/news-mask-right-corner.svg"
                            style={{ right: 0, position: "absolute" }}
                          />
                        </div>
                        <div style={{ flex: 1, marginLeft: "24px" }}>
                          <div style={{ fontWeight: "bold" }}>{item.title}</div>
                          <div style={{ color: "#333333", margin: "12px 0px" }}>
                            {moment(item.startTime).format("DD.MM.YYYY")} ~{" "}
                            {moment(item.endTime).format("DD.MM.YYYY")}
                          </div>
                          <div
                            style={{
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "4px 13px",
                              borderRadius: "8px",
                              width: "fit-content",
                              background:
                                "linear-gradient(to right, #E2FF6C, #00FFFF)",
                            }}
                          >
                            Xem chi tiết
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["box__right"]}>
              <div className={styles["right__title"]}>
                <span>Bảng xếp hạng tháng 4</span>
                <img
                  alt=""
                  src="/images/arrow-long-right.svg"
                  onClick={() => navigate("/challenges/ranking")}
                />
              </div>
              <div className={styles["right__search"]}>
                <img alt="" src="/images/magnifying-glass-icon.svg" />
                <input
                  placeholder="Tìm kiếm nhân sự"
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className={styles["right__list"]}>
                {[
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 1,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 2,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 3,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 4,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 5,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 6,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 7,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 8,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 9,
                  },
                  {
                    avatar:
                      "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                    name: "Nguyễn Thị Thương",
                    department: "Appota",
                    point: 1240,
                    rank: 10,
                  },
                ].map((item, index) => (
                  <div key={index} className={styles["list__item"]}>
                    <div className={styles["item__avatar"]}>
                      <img
                        alt=""
                        src={item.avatar}
                        className={styles["avatar__image"]}
                      />
                      {index === 0 ? (
                        <img
                          alt=""
                          src="/images/crown-icon.svg"
                          className={styles["avatar__icon"]}
                        />
                      ) : null}
                    </div>
                    <div className={styles["item__info"]}>
                      <span
                        className={`${styles["info__name"]} ${
                          index < 3 ? styles["info__name-top-3"] : ""
                        }`}
                      >
                        {item.name}
                      </span>
                      <div className={styles["info__position"]}>
                        <span>{item.department}</span>
                      </div>
                    </div>
                    <span className={styles["item__point"]}>
                      {item.point}pts
                    </span>
                    <span
                      className={`${styles["item__rank"]} ${
                        index < 3 ? styles["item__rank-top-3"] : ""
                      }`}
                    >
                      #{item.rank}
                    </span>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: "16px" }}>
                <div
                  style={{
                    height: "64px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "48px",
                    boxSizing: "border-box",
                    padding: "8px 16px 8px 8px",
                    background: "linear-gradient(to right, #E2FF6C, #00FFFF)",
                  }}
                >
                  <img
                    alt=""
                    style={{
                      width: "48px",
                      height: "48px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    src={accountState?.avatar}
                  />
                  <div style={{ width: "50%", marginLeft: "16px" }}>
                    <span style={{ fontWeight: "bold", lineHeight: "24px" }}>
                      {accountState?.name}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "14px",
                        lineHeight: "24px",
                        alignItems: "center",
                      }}
                    >
                      <span>{accountState?.department}</span>
                    </div>
                  </div>
                  <span style={{ fontWeight: "bold" }}>
                    {accountState?.total_points}pts
                  </span>
                  <span
                    style={{ flex: "1", textAlign: "end", fontWeight: "bold" }}
                  >
                    #{30}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Challenges;
