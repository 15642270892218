import callApi from ".";

const getFiles = async ({
  page = 1,
  term = "",
  departmentId,
}: {
  page?: number;
  term?: string;
  departmentId: string;
}) => {
  const { data } = await callApi({
    query: { page, term, pageSize: 10 },
    endpoint: `/web/portal/department/${departmentId}`,
  });

  return data;
};

const getCompanies = async () => {
  const { data } = await callApi({ endpoint: "/web/portal" });

  return data;
};

export { getFiles, getCompanies };
