import callApi from ".";

const submitContact = async (message: string) => {
  const { data } = await callApi({
    method: "POST",
    body: { message },
    endpoint: "/web/contact/submission",
  });

  return data;
};

export { submitContact };
