// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found_not-found-container__GWnug {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #141019;
  background: radial-gradient(at 50% -20%, #908392, #0d060e) fixed;
}
.not-found_not-found-container__GWnug .not-found_not-found-wrapper__XY1dM {
  width: 70%;
}
.not-found_not-found-container__GWnug .not-found_not-found-wrapper__XY1dM #not-found_zero__nsJzb {
  transform-box: fill-box;
  transform-origin: bottom;
}
.not-found_not-found-container__GWnug .not-found_not-found-wrapper__XY1dM #not-found_handboy__xEnRt {
  transform-box: fill-box;
  transform-origin: 98% 98%;
  animation: not-found_swing__cMuT2 ease-in-out 1.3s infinite alternate;
}
.not-found_not-found-container__GWnug .not-found_not-found-wrapper__XY1dM #not-found_girllight__zCqGM {
  transform-box: fill-box;
  transform-origin: 0% 97%;
  animation: not-found_swing__cMuT2 ease-in-out 1.3s infinite alternate;
}
.not-found_not-found-container__GWnug .not-found_not-found-wrapper__XY1dM #not-found_hairgirl__HBJm9 {
  transform-box: fill-box;
  transform-origin: 60% 0%;
  animation: not-found_swinghair__JpNjH ease-in-out 1.3s infinite alternate;
}

@keyframes not-found_swing__cMuT2 {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
@keyframes not-found_swinghair__JpNjH {
  0% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(-6deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/not-found/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,gEAAA;AACF;AACE;EACE,UAAA;AACJ;AACI;EACE,uBAAA;EACA,wBAAA;AACN;AAEI;EACE,uBAAA;EACA,yBAAA;EACA,qEAAA;AAAN;AAGI;EACE,uBAAA;EACA,wBAAA;EACA,qEAAA;AADN;AAII;EACE,uBAAA;EACA,wBAAA;EACA,yEAAA;AAFN;;AAOA;EACE;IACE,wBAAA;EAJF;EAOA;IACE,yBAAA;EALF;AACF;AAQA;EACE;IACE,uBAAA;EANF;EASA;IACE,wBAAA;EAPF;AACF","sourcesContent":[".not-found-container {\n  display: flex;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  background-color: #141019;\n  background: radial-gradient(at 50% -20%, #908392, #0d060e) fixed;\n\n  .not-found-wrapper {\n    width: 70%;\n\n    #zero {\n      transform-box: fill-box;\n      transform-origin: bottom;\n    }\n\n    #handboy {\n      transform-box: fill-box;\n      transform-origin: 98% 98%;\n      animation: swing ease-in-out 1.3s infinite alternate;\n    }\n\n    #girllight {\n      transform-box: fill-box;\n      transform-origin: 0% 97%;\n      animation: swing ease-in-out 1.3s infinite alternate;\n    }\n\n    #hairgirl {\n      transform-box: fill-box;\n      transform-origin: 60% 0%;\n      animation: swinghair ease-in-out 1.3s infinite alternate;\n    }\n  }\n}\n\n@keyframes swing {\n  0% {\n    transform: rotate(10deg);\n  }\n\n  100% {\n    transform: rotate(-10deg);\n  }\n}\n\n@keyframes swinghair {\n  0% {\n    transform: rotate(6deg);\n  }\n\n  100% {\n    transform: rotate(-6deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-found-container": `not-found_not-found-container__GWnug`,
	"not-found-wrapper": `not-found_not-found-wrapper__XY1dM`,
	"zero": `not-found_zero__nsJzb`,
	"handboy": `not-found_handboy__xEnRt`,
	"swing": `not-found_swing__cMuT2`,
	"girllight": `not-found_girllight__zCqGM`,
	"hairgirl": `not-found_hairgirl__HBJm9`,
	"swinghair": `not-found_swinghair__JpNjH`
};
export default ___CSS_LOADER_EXPORT___;
