import moment from "moment";
import { uniqBy } from "lodash";
import parse from "html-react-parser";
import { toast } from "react-toastify";
import EmojiPicker from "emoji-picker-react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { useRef, useState, useEffect, useContext, useCallback } from "react";

import {
  getComments,
  createComment,
  getNewsDetail,
  getRelatedNews,
} from "../../services/news";
import styles from "./index.module.scss";
import { AppContext } from "../../contexts";
import { CHALLENGE } from "../../constants";
import { Layout, Comment } from "../../components";
import { viewPost, reactPost } from "../../services/challenge";

const NewsDetail = () => {
  const reactions = {
    wow: { text: "Wow", icon: "wow-icon.svg" },
    sad: { text: "Buồn", icon: "sad-icon.svg" },
    haha: { text: "Haha", icon: "haha-icon.svg" },
    love: { text: "Yêu thích", icon: "heart-icon.svg" },
  };

  const navigate = useNavigate();
  const { slug }: any = useParams();
  const postContentRef = useRef<any>(null);
  const { accountState, setIsGlobalLoading } = useContext(AppContext);
  const counterFormatter = Intl.NumberFormat("en", { notation: "compact" });

  const [postViewed, setPostViewed] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);
  const [isHoverReact, setIsHoverReact] = useState(false);
  const [commentSelected, setCommentSelected] = useState<any>();
  const [childCommentValue, setChildCommentValue] = useState("");
  const [isOpenChildEmoji, setIsOpenChildEmoji] = useState(false);
  const [commentsCreated, setCommentsCreated] = useState<any[]>([]);
  const [isPendingViewPost, setIsPendingViewPost] = useState(false);
  const [childCommentsCreated, setChildCommentsCreated] = useState<any[]>([]);
  const [reactSelected, setReactSelected] = useState<CHALLENGE.ReactionType>();

  /* Post related */
  const optionsRelatedPosts = {
    queryKey: ["relatedPosts", slug],
    queryFn: () => getRelatedNews(slug),
  };
  const { data: relatedPosts, isLoading: isRelatedPostsPending } =
    useQuery(optionsRelatedPosts);
  /* Post related */

  /* Post detail */
  const optionsPost = {
    queryKey: ["post", slug],
    queryFn: () => getNewsDetail(slug),
  };
  const {
    error,
    data: detailPost,
    isLoading: isPostPending,
  } = useQuery(optionsPost);
  /* Post detail */

  // Handle on unpublish post
  if (error?.message === "Post not found") {
    navigate("/news");
  }

  /* Comments */
  const optionsComments = {
    initialPageParam: 1,
    enabled: !!detailPost?.id,
    queryKey: ["comments", detailPost],
    getNextPageParam: (lastPage: any) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    queryFn: ({ pageParam = 1 }) =>
      getComments({ page: pageParam, postId: detailPost.id }),
  };
  const {
    data: comments,
    isPending: isPendingComments,
    fetchNextPage: fetchNextComments,
  } = useInfiniteQuery(optionsComments);
  /* Comments */

  /* Children comments */
  const optionsChildrenComments = {
    initialPageParam: 1,
    enabled: !!commentSelected?.id,
    queryFn: ({ pageParam = 1 }) =>
      getComments({
        page: pageParam,
        postId: detailPost.id,
        comment_id: commentSelected.id,
      }),
    queryKey: ["childrenComments", commentSelected],
    getNextPageParam: (lastPage: any) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  };
  const { data: childrenComments, fetchNextPage: fetchNextChildrenComments } =
    useInfiniteQuery(optionsChildrenComments);
  /* Children comments */

  const handleClickReact = async (
    action: CHALLENGE.ReactAction,
    reactionType: CHALLENGE.ReactionType
  ) => {
    const { result } = await reactPost({
      action,
      reactionType,
      postId: detailPost?.id,
    });

    if (result) {
      setReactSelected(
        action === CHALLENGE.ReactAction.DISLIKE ? undefined : reactionType
      );
    }
  };

  const handleSendComment = async (comment_id?: string) => {
    const res = await createComment(detailPost?.id, {
      comment_id,
      content: comment_id ? childCommentValue : commentValue,
    });

    if (res) {
      if (comment_id) {
        setChildCommentValue("");
        setChildCommentsCreated([res, ...childCommentsCreated]);
      } else {
        setCommentValue("");
        setCommentsCreated([res, ...commentsCreated]);
      }
    }
  };

  const handleScroll = useCallback(async () => {
    if (
      window.scrollY + 218 + 455 >
      postContentRef.current?.clientHeight + 114
    ) {
      if (
        detailPost?.id &&
        !isPendingViewPost &&
        detailPost?.id !== postViewed
      ) {
        try {
          setIsPendingViewPost(true);

          const { result } = await viewPost(detailPost.id);

          if (result) {
            setIsPendingViewPost(false);
            setPostViewed(detailPost.id);
          }
        } catch (err: any) {
          toast.error(err.message, { toastId: "viewPost" });
        }
      }
    }
  }, [postViewed, detailPost?.id, isPendingViewPost]);

  useEffect(() => {
    if (detailPost?.employee_reaction) {
      setReactSelected(detailPost.employee_reaction?.reactionType);
    }

    return () => setReactSelected(undefined);
  }, [detailPost]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setIsGlobalLoading(
      isPostPending || isPendingComments || isRelatedPostsPending
    );
  }, [
    isPostPending,
    isPendingComments,
    setIsGlobalLoading,
    isRelatedPostsPending,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <Layout>
      <div className={styles["news-detail-wrapper"]}>
        {/* News detail */}
        <div className={styles["news-detail-wrapper__content"]}>
          <div className={styles["content__box"]}>
            <div className={styles["box__left"]}>
              <div className={styles["hero__content"]}>
                <div className={styles["content_title"]}>
                  <span>{detailPost?.title}</span>
                </div>
                <div className={styles["content_meta"]}>
                  <div className={styles["meta__date"]}>
                    <img alt="" src="/images/calendar-icon.svg" />
                    <span>
                      {moment(detailPost?.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  <div className={styles["meta__actions"]}>
                    <div className={styles["actions__item"]}>
                      <img alt="" src="/images/eye-gradient-icon.svg" />
                      <span>
                        {counterFormatter.format(detailPost?.total_view)}
                      </span>
                    </div>
                    <div className={styles["actions__item"]}>
                      <img alt="" src="/images/heart-gradient-icon.svg" />
                      <span>
                        {counterFormatter.format(detailPost?.total_reaction)}
                      </span>
                    </div>
                    <div className={styles["actions__item"]}>
                      <img alt="" src="/images/messenger-gradient-icon.svg" />
                      <span>
                        {counterFormatter.format(
                          detailPost?.total_comment + commentsCreated.length
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ref={postContentRef}
                className={styles["left__post-content"]}
              >
                {parse(detailPost?.content || "")}
              </div>
              <div className={styles["left__comment-area"]}>
                <div className={styles["comment-area__header"]}>
                  <div
                    className={styles["header__react"]}
                    onMouseOut={() => setIsHoverReact(false)}
                    onMouseOver={() => setIsHoverReact(true)}
                  >
                    <div
                      className={styles["react__item"]}
                      onClick={() =>
                        reactSelected
                          ? handleClickReact(
                              CHALLENGE.ReactAction.DISLIKE,
                              reactSelected
                            )
                          : handleClickReact(
                              CHALLENGE.ReactAction.LIKE,
                              CHALLENGE.ReactionType.LOVE
                            )
                      }
                    >
                      <img
                        alt=""
                        src={`/images/${
                          reactSelected
                            ? reactions[reactSelected].icon
                            : "heart-grey-icon.svg"
                        }`}
                      />
                      <span>{reactions[reactSelected || "love"].text}</span>
                    </div>
                    <div
                      style={{ display: `${isHoverReact ? "block" : "none"}` }}
                    >
                      <div className={styles["react__list"]}>
                        <img
                          alt=""
                          src="/images/heart-icon.svg"
                          className={`${styles["react-icon"]} ${
                            reactSelected === CHALLENGE.ReactionType.LOVE
                              ? styles["react-icon--active"]
                              : ""
                          }`}
                          onClick={() =>
                            handleClickReact(
                              reactSelected === CHALLENGE.ReactionType.LOVE
                                ? CHALLENGE.ReactAction.DISLIKE
                                : CHALLENGE.ReactAction.LIKE,
                              CHALLENGE.ReactionType.LOVE
                            )
                          }
                        />
                        <img
                          alt=""
                          src="/images/haha-icon.svg"
                          className={`${styles["react-icon"]} ${
                            reactSelected === CHALLENGE.ReactionType.HAHA
                              ? styles["react-icon--active"]
                              : ""
                          }`}
                          onClick={() =>
                            handleClickReact(
                              reactSelected === CHALLENGE.ReactionType.HAHA
                                ? CHALLENGE.ReactAction.DISLIKE
                                : CHALLENGE.ReactAction.LIKE,
                              CHALLENGE.ReactionType.HAHA
                            )
                          }
                        />
                        <img
                          alt=""
                          src="/images/wow-icon.svg"
                          className={`${styles["react-icon"]} ${
                            reactSelected === CHALLENGE.ReactionType.WOW
                              ? styles["react-icon--active"]
                              : ""
                          }`}
                          onClick={() =>
                            handleClickReact(
                              reactSelected === CHALLENGE.ReactionType.WOW
                                ? CHALLENGE.ReactAction.DISLIKE
                                : CHALLENGE.ReactAction.LIKE,
                              CHALLENGE.ReactionType.WOW
                            )
                          }
                        />
                        <img
                          alt=""
                          src="/images/sad-icon.svg"
                          className={`${styles["react-icon"]} ${
                            reactSelected === CHALLENGE.ReactionType.SAD
                              ? styles["react-icon--active"]
                              : ""
                          }`}
                          onClick={() =>
                            handleClickReact(
                              reactSelected === CHALLENGE.ReactionType.SAD
                                ? CHALLENGE.ReactAction.DISLIKE
                                : CHALLENGE.ReactAction.LIKE,
                              CHALLENGE.ReactionType.SAD
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <span className={styles["header__count"]}>
                    Bình luận (
                    {counterFormatter.format(
                      detailPost?.total_comment +
                        commentsCreated.length +
                        childCommentsCreated.length
                    )}
                    )
                  </span>
                </div>
                <div className={styles["comment-area__input"]}>
                  <img
                    alt=""
                    src={accountState?.avatar}
                    className={styles["input__avatar"]}
                  />
                  <input
                    value={commentValue}
                    className={styles["input__field"]}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleSendComment();
                    }}
                    onChange={(e) => {
                      const { value } = e.target;

                      if (value.length > 500) {
                        toast.info("Tối đa 500 ký tự", {
                          toastId: "comment-max-length",
                        });
                      } else {
                        setCommentValue(e.target.value);
                      }
                    }}
                  />
                  <div className={styles["input__action"]}>
                    <div className={styles["action__emoji"]}>
                      <img
                        alt=""
                        src="/images/emoji-outline-icon.svg"
                        className={styles["input__emoji-icon"]}
                        onClick={() => setIsOpenEmoji(!isOpenEmoji)}
                      />
                      <EmojiPicker
                        open={isOpenEmoji}
                        className={styles["emoji-picker"]}
                        onEmojiClick={(e) =>
                          setCommentValue((pre) => pre + e.emoji)
                        }
                      />
                    </div>
                    <img
                      alt=""
                      onClick={() => handleSendComment()}
                      src={`/images/${
                        commentValue
                          ? "email-border-active-icon.svg"
                          : "email-border-icon.svg"
                      }`}
                      className={styles["input__send-icon"]}
                    />
                  </div>
                </div>
                {/* Comments */}
                {comments
                  ? uniqBy(
                      [
                        ...commentsCreated,
                        ...comments?.pages.reduce(
                          (acc: any, curr: any) => [...acc, ...curr.data],
                          []
                        ),
                      ],
                      "id"
                    ).map((item: any) => (
                      <div key={item.id}>
                        <Comment
                          comment={item}
                          commentSelected={commentSelected}
                          setCommentSelected={setCommentSelected}
                          childCommentsCreated={childCommentsCreated}
                        >
                          <div
                            style={{ marginBottom: "24px" }}
                            className={styles["comment-area__input"]}
                          >
                            <img
                              alt=""
                              src={accountState?.avatar}
                              className={styles["input__avatar"]}
                            />
                            <input
                              value={childCommentValue}
                              onKeyDown={(e) => {
                                if (e.key === "Enter")
                                  handleSendComment(item.id);
                              }}
                              className={styles["input__field"]}
                              onChange={(e) =>
                                setChildCommentValue(e.target.value)
                              }
                            />
                            <div className={styles["input__action"]}>
                              <div className={styles["action__emoji"]}>
                                <img
                                  alt=""
                                  src="/images/emoji-outline-icon.svg"
                                  className={styles["input__emoji-icon"]}
                                  onClick={() =>
                                    setIsOpenChildEmoji(!isOpenChildEmoji)
                                  }
                                />
                                <EmojiPicker
                                  open={isOpenChildEmoji}
                                  className={styles["emoji-picker"]}
                                  onEmojiClick={(e) =>
                                    setChildCommentValue((pre) => pre + e.emoji)
                                  }
                                />
                              </div>
                              <img
                                alt=""
                                src={`/images/${
                                  childCommentValue
                                    ? "email-border-active-icon.svg"
                                    : "email-border-icon.svg"
                                }`}
                                className={styles["input__send-icon"]}
                                onClick={() => handleSendComment(item.id)}
                              />
                            </div>
                          </div>
                          {childrenComments
                            ? uniqBy(
                                [
                                  ...childCommentsCreated.filter(
                                    (child: any) => child.parent_id === item.id
                                  ),
                                  ...childrenComments?.pages.reduce(
                                    (acc: any, curr: any) => [
                                      ...acc,
                                      ...curr.data,
                                    ],
                                    []
                                  ),
                                ],
                                "id"
                              ).map((element: any) => (
                                <div key={element.id}>
                                  <Comment isChild comment={element} />
                                </div>
                              ))
                            : null}
                          {childrenComments?.pages[
                            childrenComments.pages.length - 1
                          ].hasNextPage ? (
                            <div
                              onClick={() => fetchNextChildrenComments()}
                              className={`${styles["left__get-more"]} ${styles["left__get-more-child"]}`}
                            >
                              <span>Xem thêm</span>
                              <img alt="" src="/images/double-arrow-down.svg" />
                            </div>
                          ) : null}
                        </Comment>
                      </div>
                    ))
                  : null}
                {/* Comments */}
              </div>
              {comments?.pages[comments.pages.length - 1].hasNextPage ? (
                <div
                  onClick={() => fetchNextComments()}
                  className={styles["left__get-more"]}
                >
                  <span>Xem thêm</span>
                  <img alt="" src="/images/double-arrow-down.svg" />
                </div>
              ) : null}
            </div>
            <div className={styles["box__right-wrapper"]}>
              <div className={styles["box__right"]}>
                <span className={styles["right__title"]}>Tin tức khác</span>
                <div className={styles["right__post-related"]}>
                  {relatedPosts?.map((item: any) => (
                    <div
                      key={item.id}
                      className={styles["post-related__item"]}
                      onClick={() => navigate(`/news-detail/${item.slug}`)}
                    >
                      <img
                        alt=""
                        src={item.thumbnail}
                        className={styles["item__thumbnail"]}
                      />
                      <div className={styles["item__content"]}>
                        <span className={styles["content__title"]}>
                          {item.title}
                        </span>
                        <span className={styles["content__date"]}>
                          {moment(item.createdAt).format("DD.MM.YYYY")}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* News detail */}
      </div>
      <div
        className={styles["news-detail-back-to-top"]}
        onClick={() => window.scrollTo({ top: 218, behavior: "smooth" })}
      >
        <img alt="" src="/images/double-arrow-top.svg" />
      </div>
    </Layout>
  );
};

export default NewsDetail;
