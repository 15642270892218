// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Portal_modal-wrapper__zBHlV {
  top: 0;
  left: 0;
  z-index: 6;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
}
.Portal_modal-wrapper__zBHlV .Portal_modal-wrapper__box__DPQ1E {
  width: 891px;
  position: relative;
  border-radius: 24px;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 64px 32px 32px 32px;
}
.Portal_modal-wrapper__zBHlV .Portal_modal-wrapper__box__DPQ1E .Portal_wrapper__box__content__SiP2Q {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Portal_modal-wrapper__zBHlV .Portal_modal-wrapper__box__DPQ1E .Portal_content__group-items__uDJse {
  width: 100%;
  overflow: auto;
  max-height: 536px;
}
.Portal_modal-wrapper__zBHlV .Portal_modal-wrapper__box__DPQ1E .Portal_content__group-items__uDJse::-webkit-scrollbar {
  width: 0px;
}
.Portal_modal-wrapper__zBHlV .Portal_modal-wrapper__box__DPQ1E .Portal_content__group-items__uDJse .Portal_item__Kn4EA {
  display: flex;
  padding: 16px;
  justify-content: space-between;
}
.Portal_modal-wrapper__zBHlV .Portal_modal-wrapper__box__DPQ1E .Portal_content__group-items__uDJse .Portal_item__Kn4EA:hover {
  background: rgb(225, 241, 255);
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/Portal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,MAAA;EACA,OAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,qCAAA;AACF;AACE;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,yBAAA;EACA,4BAAA;AACJ;AACI;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AACN;AAEI;EACE,WAAA;EACA,cAAA;EACA,iBAAA;AAAN;AAEM;EACE,UAAA;AAAR;AAGM;EACE,aAAA;EACA,aAAA;EACA,8BAAA;AADR;AAGQ;EACE,8BAAA;AADV","sourcesContent":[".modal-wrapper {\n  top: 0;\n  left: 0;\n  z-index: 6;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  position: fixed;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(0, 0, 0, 0.25);\n\n  .modal-wrapper__box {\n    width: 891px;\n    position: relative;\n    border-radius: 24px;\n    box-sizing: border-box;\n    background-color: #ffffff;\n    padding: 64px 32px 32px 32px;\n\n    .wrapper__box__content {\n      display: flex;\n      align-items: center;\n      flex-direction: column;\n    }\n\n    .content__group-items {\n      width: 100%;\n      overflow: auto;\n      max-height: 536px;\n\n      &::-webkit-scrollbar {\n        width: 0px;\n      }\n\n      .item {\n        display: flex;\n        padding: 16px;\n        justify-content: space-between;\n\n        &:hover {\n          background: rgba(225, 241, 255, 1);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-wrapper": `Portal_modal-wrapper__zBHlV`,
	"modal-wrapper__box": `Portal_modal-wrapper__box__DPQ1E`,
	"wrapper__box__content": `Portal_wrapper__box__content__SiP2Q`,
	"content__group-items": `Portal_content__group-items__uDJse`,
	"item": `Portal_item__Kn4EA`
};
export default ___CSS_LOADER_EXPORT___;
