import callApi from ".";

const defautlPageSize = 6;

const getNotifications = async (query: { isRead?: boolean, page: number }) => {
  const { data } = await callApi({ endpoint: "/web/notifications", query: {...query, pageSize: defautlPageSize} });

  return data;
};

const getUnreadNotificationCount = async () => {
  const { data } = await callApi({ endpoint: "/web/notifications/count-unread" });

  return data;
};

const putReadNotifications = async (params: {
  id?: string;
  isAll?: boolean;
}) => {
  const { data } = await callApi({
    body: params,
    method: "PUT",
    endpoint: "/web/notifications/read",
  });

  return data?.result;
};

export { getNotifications, putReadNotifications, getUnreadNotificationCount };
