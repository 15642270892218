import { debounce } from "lodash";
import { useRef, useState, useContext } from "react";

import styles from "./index.module.scss";
import { Layout } from "../../components";
import { AppContext } from "../../contexts";

const ChallengeRanking = () => {
  const { accountState } = useContext(AppContext);

  const employeeInput = useRef<any>(null);
  const [query, setQuery] = useState({ event: "", month: "" });
  const [isShowInputClose, setIsShowInputClose] = useState(false);

  const handleChangeEmployee = debounce((e: any) => {
    setIsShowInputClose(!!e.target.value);
    console.log("Employee: ", e.target.value);
  }, 1000);

  return (
    <Layout>
      <div className={styles["challenge-ranking-wrapper"]}>
        <div className={styles["challenge-ranking-wrapper__content"]}>
          <span className={styles["content__title"]}>
            Bảng xếp hạng tháng 4
          </span>
          <div className={styles["content__box"]}>
            {/* Filter */}
            <div className={styles["content__filter"]}>
              <label className={styles["filter__field--input"]}>
                <img alt="" src="/images/magnifying-glass-icon.svg" />
                <input
                  ref={employeeInput}
                  onChange={handleChangeEmployee}
                  placeholder="Tìm kiếm nhân viên"
                />
                {isShowInputClose ? (
                  <img
                    alt=""
                    src="/images/close-icon.svg"
                    onClick={() => {
                      setIsShowInputClose(false);
                      employeeInput.current.value = "";
                    }}
                    className={styles["input__close-icon"]}
                  />
                ) : null}
              </label>
              <div className={styles["filter__field--select"]}>
                <div className={styles["select__line"]} />
                <select
                  value={query.event}
                  onChange={(e) =>
                    setQuery({ ...query, event: e.target.value })
                  }
                  style={{
                    appearance: `${query.event ? "none" : "auto"}`,
                  }}
                >
                  <option hidden value="" disabled>
                    Tên sự kiện
                  </option>
                  <option value={"vue"}>Vue</option>
                  <option value={"react"}>React</option>
                  <option value={"svelte"}>Svelte</option>
                </select>
                {query.event ? (
                  <img
                    alt=""
                    src="/images/close-icon.svg"
                    className={styles["select__close-icon"]}
                    onClick={() => setQuery({ ...query, event: "" })}
                  />
                ) : null}
              </div>
              <div className={styles["filter__field--select"]}>
                <div className={styles["select__line"]} />
                <select
                  value={query.month}
                  onChange={(e) =>
                    setQuery({ ...query, month: e.target.value })
                  }
                  style={{
                    appearance: `${query.month ? "none" : "auto"}`,
                  }}
                >
                  <option hidden value="" disabled>
                    Tháng
                  </option>
                  {Array.from(Array(12).keys()).map((item) => (
                    <option key={item} value={item + 1}>
                      Tháng {item + 1}
                    </option>
                  ))}
                </select>
                {query.month ? (
                  <img
                    alt=""
                    src="/images/close-icon.svg"
                    className={styles["select__close-icon"]}
                    onClick={() => setQuery({ ...query, month: "" })}
                  />
                ) : null}
              </div>
            </div>
            {/* Filter */}
            {/* Table */}
            <div className={styles["content__table"]}>
              <div className={styles["table__heading"]}>
                <span className={styles["heading__name"]}>Hạng</span>
                <span className={styles["heading__point"]}>Challenges 1</span>
                <span className={styles["heading__point"]}>Challenges 2</span>
                <span
                  className={`${styles["heading__point"]} ${styles["heading__current-point"]}`}
                >
                  Challenges 3
                </span>
                <span className={styles["heading__point"]}>Challenges 4</span>
                <span
                  className={`${styles["heading__point"]} ${styles["heading__total-point"]}`}
                >
                  Tổng điểm
                </span>
              </div>
              <div className={styles["table__list-wrapper"]}>
                <div className={styles["table__list"]}>
                  {[
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 1,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 2,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 3,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 4,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 5,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 6,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 7,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 8,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 9,
                    },
                    {
                      avatar:
                        "https://s3-alpha-sig.figma.com/img/2298/9936/4299d92cba9976accd7eefa1406d3cfe?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RQXUWew3k-PKIz0r4r8j21B~cK3rnkQeJRuaslSzP3eeSui2g1KnMbGvUzsOUOJmknw2sE5M1okUf~wJ~OOnvGl~l9pKRxlFyh56cTndXygLiEKs5XSb~9kNgpLGtRUuzgZ44W44iHMTYmK0MnIrK6FRO4n-Ruzi-elaJ9gcI-f3rMV~RQ19oFqT5OXKPO3liqUUN5GlGaC9Q7xsIBQMX8V~9mCFO-526Xt8RQad75BGMjoMWGYLIuV5r71ML1XKrmsCo2R-~P6OG68j7nHfKo6ZPL5mB7KpwxRwnM2K83ql1ChnNm1vviAvoMsPoe5OhfECcXfpwiMhE~BwvW599g__",
                      name: "Nguyễn Thị Thương",
                      department: "Appota",
                      position: "CDO",
                      points: [
                        { point: 100, id: "challenges-1" },
                        { point: 240, id: "challenges-2" },
                        { point: 500, id: "challenges-3" },
                      ],
                      rank: 10,
                    },
                  ].map((item, index) => (
                    <div key={index} className={styles["list__item"]}>
                      <div className={styles["item__rank"]}>
                        <span
                          className={`${styles["rank__number"]} ${
                            item.rank < 4 ? styles["rank__top-3"] : ""
                          }`}
                        >
                          #{item.rank}
                        </span>
                        <div className={styles["rank__avatar"]}>
                          <img
                            alt=""
                            src={item.avatar}
                            className={styles["avatar__image"]}
                          />
                          {index === 0 ? (
                            <img
                              alt=""
                              src="/images/crown-icon.svg"
                              className={styles["avatar__icon"]}
                            />
                          ) : null}
                        </div>
                        <div className={styles["rank__info"]}>
                          <span
                            className={`${styles["info__name"]} ${
                              item.rank < 4 ? styles["info__name-top-3"] : ""
                            }`}
                          >
                            {item.name}
                          </span>
                          <div className={styles["info__company"]}>
                            <span>{item.position}</span>
                            <div className={styles["position__department"]} />
                            <span>{item.department}</span>
                          </div>
                        </div>
                      </div>
                      {item.points.map((element, index) => (
                        <div
                          key={element.id}
                          className={`${styles["item__point"]} ${
                            index === item.points.length - 1 &&
                            styles["item__current-point"]
                          }`}
                        >
                          {element.point}pts
                        </div>
                      ))}
                      {Array.from(Array(4 - item.points.length).keys()).map(
                        (_, key) => (
                          <div key={key} className={styles["item__point"]}>
                            -
                          </div>
                        )
                      )}
                      <div
                        className={`${styles["item__point"]} ${styles["item__total-point"]}`}
                      >
                        {item.points.reduce((a, b) => a + b.point, 0)}pts
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Table */}
            <div className={styles["content__footer"]}>
              <div className={styles["list__item"]}>
                <div className={styles["item__rank"]}>
                  <span className={styles["rank__number"]}>#{30}</span>
                  <div className={styles["rank__avatar"]}>
                    <img
                      alt=""
                      src={accountState.avatar}
                      className={styles["avatar__image"]}
                    />
                  </div>
                  <div className={styles["rank__info"]}>
                    <span className={styles["info__name"]}>
                      {accountState.name}
                    </span>
                    <div className={styles["info__company"]}>
                      <span>{accountState.position}</span>
                      <div className={styles["position__department"]} />
                      <span>{accountState.department}</span>
                    </div>
                  </div>
                </div>
                {[
                  { point: 100, id: "challenges-1" },
                  { point: 240, id: "challenges-2" },
                  { point: 500, id: "challenges-3" },
                ].map((element, index) => (
                  <div key={element.id} className={styles["item__point"]}>
                    {element.point}pts
                  </div>
                ))}
                {Array.from(Array(4 - 3).keys()).map((_, key) => (
                  <div key={key} className={styles["item__point"]}>
                    -
                  </div>
                ))}
                <div
                  className={`${styles["item__point"]} ${styles["item__total-point"]}`}
                >
                  {[
                    { point: 100, id: "challenges-1" },
                    { point: 240, id: "challenges-2" },
                    { point: 500, id: "challenges-3" },
                  ].reduce((a, b) => a + b.point, 0)}
                  pts
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChallengeRanking;
