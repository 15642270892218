import { useContext, useEffect, useState } from "react";

import {
  getUnreadNotificationCount,
  putReadNotifications,
} from "../../services/notification";
import styles from "./index.module.scss";
import NotificationList from "./notification-list";
import { AppContext } from "../../contexts";
import { useQuery } from "@tanstack/react-query";
import { NOTIFCATION_ACTION } from "../../contexts/notification";

type Props = {
  isOpen?: boolean;
  openPointPopup: Function;
};

const NotificationPopup = ({ isOpen = false, openPointPopup }: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [refresher, setRefresher] = useState(0);
  const { notificationState, notificationDispatch } = useContext(AppContext);

  const { data: totalUnread, refetch: refetchUnreadCount } = useQuery({
    enabled: false,
    queryKey: ["total-unread-notification"],
    queryFn: () => getUnreadNotificationCount(),
  });

  useEffect(() => {
    totalUnread && notificationDispatch({ payload: {totalUnread: totalUnread.total}, type: NOTIFCATION_ACTION.UPDATE_UNREAD });
  }, [totalUnread, notificationDispatch])

  const handleClickReadAll = async () => {
    const result = await putReadNotifications({ isAll: true });

    if (result) refreshAllList();
  };

  const refreshAllList = () => {
    setRefresher(refresher + 1)
    refetchUnreadCount()
  }

  if (!isOpen) return null;

  return (
    <div className={styles["popup-wrapper"]}>
      <div className={styles["popup-wrapper__title"]}>
        <span
          onClick={() => setTabIndex(0)}
          className={`${tabIndex === 0 ? styles["title__tab-selected"] : ""}`}
        >
          Tất cả
        </span>
        <span
          onClick={() => setTabIndex(1)}
          className={`${tabIndex === 1 ? styles["title__tab-selected"] : ""}`}
        >
          Chưa xem{" "}
          {notificationState.totalUnread ? `(${notificationState.totalUnread})` : ""}
        </span>
        <span
          onClick={() => setTabIndex(2)}
          className={`${tabIndex === 2 ? styles["title__tab-selected"] : ""}`}
        >
          Đã xem
        </span>
        <img
          alt=""
          onClick={handleClickReadAll}
          src="/images/checked-icon.svg"
        />
      </div>
      <div className={styles["popup-wrapper__content"]}>
        <div className={`${tabIndex !== 0 ? styles["hidden__tab"] : ""}`}>
          <NotificationList refresher={refreshAllList} openPointPopup={openPointPopup} onRefresher={refresher} />
        </div>
        <div className={`${tabIndex !== 1 ? styles["hidden__tab"] : ""}`}>
          <NotificationList isRead={false} refresher={refreshAllList} openPointPopup={openPointPopup} onRefresher={refresher} />
        </div>
        <div className={`${tabIndex !== 2 ? styles["hidden__tab"] : ""}`}>
          <NotificationList isRead={true} refresher={refreshAllList} openPointPopup={openPointPopup} onRefresher={refresher} />
        </div>
      </div>
    </div>
  );
};

export default NotificationPopup;
