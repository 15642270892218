import callApi from ".";

const getNews = async (page = 1) => {
  const { data } = await callApi({
    endpoint: "/web/posts",
    query: { page, pageSize: 9 },
  });

  return data;
};

const getComments = async ({
  postId,
  page = 1,
  comment_id,
  pageSize = 5,
}: {
  page?: number;
  postId: string;
  pageSize?: number;
  comment_id?: string;
}) => {
  const { data } = await callApi({
    query: { page, pageSize, comment_id },
    endpoint: `/web/posts/comment/${postId}`,
  });

  return data;
};

const createComment = async (
  postId: string,
  data: { content: string; comment_id?: string }
) => {
  const res = await callApi({
    body: data,
    method: "POST",
    endpoint: `/web/posts/comment/${postId}`,
  });

  return res.data;
};

const getNewsDetail = async (slug: string) => {
  const { data } = await callApi({
    endpoint: `/web/posts/${slug}`,
  });

  return data;
};

const getRelatedNews = async (slug: string) => {
  const { data } = await callApi({
    endpoint: `/web/posts/related/${slug}`,
  });

  return data;
};

const getSpotlightNews = async () => {
  const { data } = await callApi({
    endpoint: "/web/posts/spotlight",
  });

  return data;
};

export {
  getNews,
  getComments,
  createComment,
  getNewsDetail,
  getRelatedNews,
  getSpotlightNews,
};
