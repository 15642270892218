import { useEffect } from "react";

import styles from "./index.module.scss";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

const Congratulations = ({ onClose, isOpen = false }: Props) => {
  useEffect(() => {
    document.body.style.overflow = `${isOpen ? "hidden" : "unset"}`;
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles["congratulations-wrapper"]}>
      {Array.from(Array(500).keys()).map((item, index) => (
        <div key={index} className={styles["confetti-" + item]} />
      ))}
      <div className={styles["congratulations-wrapper__modal"]}>
        <img alt="" src="/images/congratulations-background-modal.svg" />
        <div onClick={onClose} className={styles["main__close"]}>
          <img alt="" src="/images/close-icon.svg" />
        </div>
        <div className={styles["modal__content"]}>
          <span className={styles["content__point"]}>10.000 điểm</span>
          <span className={styles["content__description"]}>
            Chúc mừng bạn đã đạt mốc 10,000 điểm, hãy đổi ngay những phần quà
            hấp dẫn
          </span>
          <div className={styles["content__button"]}>
            <span>Đổi điểm ngay</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
