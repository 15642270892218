import Cookies from "js-cookie";
import { useEffect, useContext } from "react";

import styles from "./index.module.scss";
import { AppContext } from "../../../contexts";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

const EmployeeModal = ({ onClose, isOpen = false }: Props) => {
  const { accountState } = useContext(AppContext);

  const handleClickSignOut = async () => {
    Cookies.remove("accessTokenEmp");
    Cookies.remove("refreshTokenEmp");

    window.location.href = "/";
  };

  useEffect(() => {
    document.body.style.overflow = `${isOpen ? "hidden" : "unset"}`;
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles["modal-wrapper"]}>
      <div className={styles["modal-wrapper__main"]}>
        <div className={styles["main__background"]}>
          <img
            alt=""
            className={styles["background__image"]}
            src="/images/employee-background-modal.svg"
          />
          <div onClick={onClose} className={styles["background__close"]}>
            <img alt="" src="/images/close-icon.svg" />
          </div>
        </div>
        <div className={styles["main__content"]}>
          <div className={styles["content__wrapper"]}>
            <div className={styles["content__image"]}>
              <img
                alt=""
                src={accountState?.avatar}
                className={styles["image-1"]}
              />
            </div>
            <div className={styles["content__info"]}>
              <span className={styles["info__name"]}>{accountState?.name}</span>
              <span className={styles["info__code"]}>
                Mã nhân viên: <span>{accountState?.employee_code}</span>
              </span>
            </div>
            <div className={styles["content__company"]}>
              <div className={styles["info__item"]}>
                <span className={styles["item__key"]}>Vị trí:</span>
                <span className={styles["item__value"]}>
                  {accountState?.position}
                </span>
              </div>
              <div className={styles["info__separation"]} />
              <div className={styles["info__item"]}>
                <span className={styles["item__key"]}>Bộ Phận:</span>
                <span className={styles["item__value"]}>
                  {accountState?.department}
                </span>
              </div>
            </div>
            <div className={styles["content__point"]}>
              <span className={styles["point__title"]}>Điểm của bạn</span>
              <span className={styles["point__value"]}>
                {accountState?.total_points.toLocaleString()}
              </span>
            </div>
            <div
              onClick={handleClickSignOut}
              className={styles["content__button"]}
            >
              <span>Đăng xuất</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeModal;
