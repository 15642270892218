import { useContext } from "react";

import Footer from "../Footer";
import Header from "../Header";
import { FeelingModal } from "../Modals";
import styles from "./index.module.scss";
import { Loading } from "../../components";
import { AppContext } from "../../contexts";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const { isGlobalLoading } = useContext(AppContext);

  if (isGlobalLoading) return <Loading />;

  return (
    <div className={styles["layout-wrapper"]}>
      <Header />
      {children}
      <Footer />
      <FeelingModal />
    </div>
  );
};

export default Layout;
