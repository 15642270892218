enum EmotionType {
  SAD = "SAD",
  HAPPY = "HAPPY",
  NEUTRAL = "NEUTRAL",
}

enum ReactAction {
  LIKE = "like",
  DISLIKE = "dislike",
}

enum ReactionType {
  SAD = "sad",
  WOW = "wow",
  HAHA = "haha",
  LOVE = "love",
}

export { EmotionType, ReactAction, ReactionType };
