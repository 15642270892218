import { useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import styles from "./index.module.scss";
import { getFiles } from "../../../services/portal";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  department: any;
  isOpen?: boolean;
  onClose: () => void;
};

const PortalModal = ({ onClose, department, isOpen = false }: Props) => {
  const optionsFiles = {
    initialPageParam: 1,
    enabled: !!department?.id,
    queryKey: ["department-files", department?.id],
    queryFn: ({ pageParam = 1 }) =>
      getFiles({ page: pageParam, departmentId: department?.id }),
    getNextPageParam: (_lastPage: any, pages: any) => {
      if (pages.length < pages[0]?.pageCount) {
        return pages.length + 1;
      }

      return undefined;
    },
  };

  const {
    hasNextPage,
    fetchNextPage,
    data: listFiles,
  } = useInfiniteQuery(optionsFiles);

  const filesResult = listFiles?.pages?.reduce((acc, page) => {
    return [...acc, ...page?.data];
  }, []);

  const handleClickFile = (path: string) => {
    window.open(`${process.env.REACT_APP_FILE_URL}/${path}`);
  };

  useEffect(() => {
    document.body.style.overflow = `${isOpen ? "hidden" : "unset"}`;
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles["modal-wrapper"]}>
      <div className={styles["modal-wrapper__box"]}>
        <img
          alt=""
          style={{
            left: "50%",
            top: "-40px",
            width: "80px",
            height: "80px",
            borderRadius: "24px",
            position: "absolute",
            transform: "translateX(-50%)",
          }}
          src="/images/avatar-portal.svg"
        />

        <div className={styles["wrapper__box__content"]}>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "16px",
            }}
          >
            {department.name}
          </span>
          <div id="scrollableDiv" className={styles["content__group-items"]}>
            <InfiniteScroll
              hasMore={hasNextPage}
              loader={<h4>Loading...</h4>}
              next={() => fetchNextPage()}
              scrollableTarget="scrollableDiv"
              dataLength={filesResult ? filesResult.length : 0} //This is important field to render the next data
            >
              {filesResult &&
                filesResult.map((item: any) => (
                  <div key={item.id} className={styles["item"]}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        alt="img"
                        style={{ width: "24px", height: "24px" }}
                        src={`${process.env.REACT_APP_FILE_URL}/file-type/${item.fileType}.png`}
                      />
                      <span style={{ marginLeft: "4px" }}>{item.fileName}</span>
                    </div>
                    <div>
                      <img
                        alt=""
                        src="/images/blue-eye-icon.svg"
                        onClick={() => handleClickFile(item.filePath)}
                        style={{ cursor: "pointer", margin: "0px 24px" }}
                      />
                    </div>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: "24px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#888888" }}>
              {listFiles?.pages[0]?.itemCount} Files
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                onClick={onClose}
                style={{
                  border: "none",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  marginLeft: "12px",
                  borderRadius: "4px",
                  padding: "8px 16px",
                  backgroundColor: "#0E2FDB",
                }}
              >
                Đóng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortalModal;
