import Footer from "./Footer";
import Header from "./Header";
import Layout from "./Layout";
import Comment from "./Comment";
import Loading from "./Loading";
import LuckySpin from "./LuckySpin";
import PrivateRoute from "./PrivateRoute";
import Congratulations from "./Congratulations";
import NotificationPopup from "./NotificationPopup";

export {
  Footer,
  Header,
  Layout,
  Comment,
  Loading,
  LuckySpin,
  PrivateRoute,
  Congratulations,
  NotificationPopup,
};
