import { TAction } from ".";

interface INotificationState {
  totalUnread: number;
}

const initialNotificationState: INotificationState = {
  totalUnread: 0,
};

export enum NOTIFCATION_ACTION {
  UPDATE_UNREAD = 'UPDATE_UNREAD'
}

const NotificationReducer = (state: INotificationState, action: TAction) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFCATION_ACTION.UPDATE_UNREAD:
      return { ...state, ...payload };

    default:
      throw new Error(`Action type ${type} is undefined`);
  }
};

export { NotificationReducer, initialNotificationState };
