import moment from "moment";
import {  useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import {
  getNotifications,
} from "../../services/notification";
import styles from "./index.module.scss";
import NotificationItem, { Notification } from "./notification-item";
import { flatMap } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  isRead?: boolean;
  refresher: Function;
  openPointPopup: Function;
  onRefresher: number,
};


interface INotificationPage {
  hasNextPage: boolean;
  items: Array<Notification>
  page: number
}

const NotificationList = ({ isRead, onRefresher, refresher, openPointPopup }: Props) => {
  let queryKey = 'all'
  if (isRead !== undefined) {
    queryKey = isRead ? 'read' : 'unread'
  }

  const {
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetching,
    data: notifications,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [`notification-${queryKey}`],
    queryFn: ({pageParam} : {pageParam: number}) => getNotifications({isRead, page: pageParam}),
    getNextPageParam: (lastPage: any) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  });

  useEffect(() => {
    refetch();
  }, [onRefresher, refetch]);

  const filterRecentNotification = (
    notiList: INotificationPage[],
    isRecent = true
  ) => {
    const today = moment();
    const items = flatMap(notiList, (el) => el.items)

    return items.filter((noti) =>
      isRecent
        ? moment(noti.createdAt).diff(today, "days") === 0
        : moment(noti.createdAt).diff(today, "days") !== 0
    );
  };

  if (!notifications) {
    return <></>
  }


  return (
    <InfiniteScroll
        hasMore={hasNextPage}
        loader={<></>}
        next={() => fetchNextPage()}
        scrollableTarget={`scrollable-${queryKey}}`}
        dataLength={notifications.pages ? notifications.pages.length : 0} //This is important field to render the next data
      >
      <div id={`scrollable-${queryKey}}`} className={styles["content__tab"]}>
        {!!filterRecentNotification(notifications.pages).length && (
          <div className={styles["tab--today"]}>
            <span className={styles["tab__title"]}>Hôm nay</span>
            {filterRecentNotification(notifications?.pages).map(
              (notification: Notification) => (
                <NotificationItem
                  openPoint={openPointPopup}
                  notification={notification}
                  key={notification.id}
                  refetchNotifications={refresher}
                />
              )
            )}
          </div>
        )}
        {!!filterRecentNotification(notifications.pages, false).length && (
            <div className={styles["tab--previous-days"]}>
              <span className={styles["tab__title"]}>Trước đó</span>
              {filterRecentNotification(notifications.pages, false).map(
                (notification: Notification) => (
                  <NotificationItem
                    openPoint={openPointPopup}
                    notification={notification}
                    key={notification.id}
                    refetchNotifications={refresher}
                  />
                )
              )}
            </div>
        )}
        {isFetching &&
          <p className={styles["tab__loader"]}>Đang tải ...</p>
        }
      </div>
    </InfiniteScroll>
  );
};

export default NotificationList;
