import styles from "./index.module.scss";
import { Layout } from "../../components";
import { ContactModal } from "../../components/Modals";

const Contact = () => {
  return (
    <Layout>
      <div className={styles["contact-wrapper"]}>
        <ContactModal />
      </div>
    </Layout>
  );
};

export default Contact;
