import Cookies from "js-cookie";

import callApi from ".";

const signout = async () => {
  return callApi({ method: "POST", endpoint: "/web/auth/signout" });
};

const getToken = async (token: string) => {
  const { data } = await callApi({
    method: "POST",
    body: { token },
    endpoint: "/web/auth/oauth/login",
  });

  return data;
};

const getSigninURL = async () => {
  const { data } = await callApi({
    method: "POST",
    endpoint: "/web/auth/oauth/login-code",
    body: { redirectUrl: window.location.origin },
  });

  return data;
};

const refreshToken = async () => {
  try {
    const { data } = await callApi(
      { method: "POST", endpoint: "/web/auth/refresh-token" },
      false
    );

    if (data?.accessToken && data?.refreshToken) {
      const { accessToken, refreshToken } = data;

      Cookies.set("accessTokenEmp", accessToken, { expires: 365 });
      Cookies.set("refreshTokenEmp", refreshToken, { expires: 365 });
    }

    return data;
  } catch (err) {
    Cookies.remove("accessTokenEmp");
    Cookies.remove("refreshTokenEmp");

    window.location.href = "/";
  }
};

export { signout, getToken, getSigninURL, refreshToken };
