import { useState } from "react";

import styles from "./index.module.scss";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

const LuckySpin = ({ onClose, isOpen = false }: Props) => {
  const [isRotate, setIsRotate] = useState(false);

  const handleCloseModal = () => {
    setIsRotate(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className={styles["lucky-spin-wrapper"]}>
      <div className={styles["lucky-spin-wrapper__modal"]}>
        <div className={styles["modal__title"]}>
          <img
            alt=""
            src="/images/speaker.svg"
            className={styles["title__icon"]}
          />
          <span>Chúc mừng </span>
          <span className={styles["title__name"]}>Đinh Thuỳ Linh</span>
          <span> đã trúng thưởng </span>
          <span className={styles["title__gif"]}>
            Voucher Appota Coffee x10
          </span>
        </div>
        <div className={styles["modal__spinner"]}>
          <div
            className={`${styles["modal__spinner__wheel"]} ${
              isRotate ? styles["modal__spinner__wheel-rotate"] : ""
            }`}
          />
          <img
            alt=""
            className={styles["modal__spinner__point"]}
            src="/images/spinner-point.svg"
          />
          <img
            alt=""
            src="/images/spinner-arrow.svg"
            onClick={() => setIsRotate(!isRotate)}
            className={styles["modal__spinner__arrow"]}
          />
        </div>
        <img
          alt=""
          onClick={handleCloseModal}
          src="/images/close-outline-icon.svg"
          className={styles["modal__spinner__close"]}
        />
      </div>
    </div>
  );
};

export default LuckySpin;
