import { useQuery } from "@tanstack/react-query";
import { useState, useEffect, useContext } from "react";

import styles from "./index.module.scss";
import { Layout } from "../../components";
import { AppContext } from "../../contexts";
import { getCompanies } from "../../services/portal";
import { PortalModal } from "../../components/Modals";

const Portal = () => {
  const { setIsGlobalLoading } = useContext(AppContext);

  const [companySelected, setCompanySelected] = useState<any>();
  const [departmentSelected, setDepartmentSelected] = useState<any>();

  const { data: companies, isPending: isCompaniesPending } = useQuery({
    queryKey: ["companies"],
    queryFn: () => getCompanies(),
  });

  useEffect(() => {
    setCompanySelected(companies?.[0]);
    setIsGlobalLoading(isCompaniesPending);
  }, [companies, isCompaniesPending, setIsGlobalLoading]);

  return (
    <Layout>
      <div className={styles["portal-wrapper"]}>
        <div className={styles["portal-wrapper__content"]}>
          <span className={styles["content__title"]}>Portal</span>
          <div className={styles["content__box"]}>
            <img
              alt=""
              className={styles["box__background"]}
              src="/images/portal-background.svg"
            />
            <img
              alt=""
              src="/images/mars.gif"
              className={styles["box__background-top-left-corner"]}
            />
            <div className={styles["box__content"]}>
              <div className={styles["content__left"]}>
                {companies?.map((item: any) => (
                  <div
                    key={item.id}
                    onClick={() => setCompanySelected(item)}
                    className={`${styles["left__item"]} ${
                      companySelected?.id === item.id
                        ? styles["left__item--active"]
                        : ""
                    }`}
                  >
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
              <div className={styles["content__right"]}>
                <div className={styles["right__content"]}>
                  {companySelected?.departments?.map((item: any) => (
                    <div key={item.id} className={styles["content__item"]}>
                      <div
                        className={styles["item__header"]}
                        onClick={() => setDepartmentSelected(item)}
                      >
                        <div className={styles["header__avatar"]}>
                          <img alt="" src="/images/avatar-portal.svg" />
                          <span>{item.name}</span>
                        </div>
                        <img alt="" src="/images/arrow-right-icon.svg" />
                      </div>
                      <div className={styles["item__files"]}>
                        {item.files?.slice(0, 3).map((element: any) => (
                          <div key={element.id} className={styles["file"]}>
                            <img
                              alt=""
                              src={`${process.env.REACT_APP_FILE_URL}/file-type/${element.fileType}.png`}
                            />
                            <span
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_FILE_URL}/${element.filePath}`
                                )
                              }
                            >
                              {element.fileName}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PortalModal
        isOpen={!!departmentSelected}
        department={departmentSelected}
        onClose={() => setDepartmentSelected(null)}
      />
    </Layout>
  );
};

export default Portal;
