import Cookies from "js-cookie";
import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const token = Cookies.get("accessTokenEmp");

  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
