import Cookies from "js-cookie";
import { useState, useReducer } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";

import {
  Home,
  News,
  Portal,
  Signin,
  Contact,
  Rewards,
  Rankings,
  NotFound,
  // Challenges,
  NewsDetail,
  ChallengeRanking,
} from "./pages";
import {
  NotificationReducer,
  initialNotificationState,
} from "./contexts/notification";
import { AppContext } from "./contexts";
import { PrivateRoute } from "./components";
import { AccountReducer, initialAccountState } from "./contexts/account";

const App = () => {
  const token = Cookies.get("accessTokenEmp");

  const [accountState, accountDispatch] = useReducer(
    AccountReducer,
    initialAccountState
  );

  const [notificationState, notificationDispatch] = useReducer(
    NotificationReducer,
    initialNotificationState
  );

  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{
        accountState,
        accountDispatch,
        isGlobalLoading,
        notificationState,
        setIsGlobalLoading,
        notificationDispatch,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route path="/portal" element={<Portal />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/rankings" element={<Rankings />} />
            {/* <Route path="/challenges" element={<Challenges />} /> */}
            <Route path="/news-detail/:slug" element={<NewsDetail />} />
            <Route path="/challenges/ranking" element={<ChallengeRanking />} />
          </Route>
          <Route
            path="/"
            element={token ? <Navigate to="/home" /> : <Signin />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
