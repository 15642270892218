import { createContext } from "react";

import { initialAccountState } from "./account";
import { initialNotificationState } from "./notification";

export type TAction = {
  type: string;
  payload: any;
};

const AppContext = createContext({
  isGlobalLoading: false,
  accountState: initialAccountState,
  notificationState: initialNotificationState,
  accountDispatch: (action: TAction) => {},
  notificationDispatch: (action: TAction) => {},
  setIsGlobalLoading: (isLoading: boolean) => {},
});

export { AppContext };
