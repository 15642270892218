import styles from "./index.module.scss";

const Footer = () => {
  return (
    <div className={styles["footer-wrapper"]}>
      <span>Copyright © Appota {new Date().getFullYear()}</span>
    </div>
  );
};

export default Footer;
