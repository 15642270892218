import styles from "./index.module.scss";

const Loading = () => {
  return (
    <div className={styles["loading-wrapper"]}>
      <svg width="240" height="240" viewBox="0 0 240 240">
        <circle
          r="105"
          cx="120"
          cy="120"
          fill="none"
          stroke="#000"
          strokeWidth="20"
          strokeLinecap="round"
          strokeDasharray="0 660"
          strokeDashoffset="-330"
          className={`${styles["ring"]} ${styles["ring__first"]}`}
        />
        <circle
          r="35"
          cx="120"
          cy="120"
          fill="none"
          stroke="#000"
          strokeWidth="20"
          strokeLinecap="round"
          strokeDasharray="0 220"
          strokeDashoffset="-110"
          className={`${styles["ring"]} ${styles["ring__second"]}`}
        />
        <circle
          r="70"
          cx="85"
          cy="120"
          fill="none"
          stroke="#000"
          strokeWidth="20"
          strokeLinecap="round"
          strokeDasharray="0 440"
          className={`${styles["ring"]} ${styles["ring__third"]}`}
        />
        <circle
          r="70"
          cx="155"
          cy="120"
          fill="none"
          stroke="#000"
          strokeWidth="20"
          strokeLinecap="round"
          strokeDasharray="0 440"
          className={`${styles["ring"]} ${styles["ring__fourth"]}`}
        />
      </svg>
    </div>
  );
};

export default Loading;
