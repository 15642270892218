import React from "react";
import moment from "moment";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

moment.updateLocale("vi", {
  relativeTime: {
    h: "1 giờ",
    y: "1 năm",
    d: "1 ngày",
    m: "1 phút",
    w: "1 tuần",
    hh: "%d giờ",
    M: "1 tháng",
    yy: "%d năm",
    dd: "%d ngày",
    mm: "%d phút",
    s: "Vài giây",
    ss: "%d giây",
    ww: "%d tuần",
    MM: "%d tháng",
    past: "%s trước",
  },
});
moment.locale("vi");

root.render(
  <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
      <App />
      <ToastContainer />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
