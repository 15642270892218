import { TAction } from ".";

interface IAccountState {
  id: string;
  name: string;
  email: string;
  avatar: string;
  position: string;
  department: string;
  phone: string | null;
  total_points: number;
  employee_code: string;
  shared_emotion: boolean;
}

const initialAccountState: IAccountState = {
  id: "",
  name: "",
  email: "",
  avatar: "",
  phone: null,
  position: "",
  department: "",
  total_points: 0,
  employee_code: "",
  shared_emotion: false,
};

const AccountReducer = (state: IAccountState, action: TAction) => {
  const { type, payload } = action;

  switch (type) {
    case "S-ACCOUNT":
      return { ...state, ...payload };

    case "U-SHARED_EMOTION":
      return { ...state, shared_emotion: payload };

    default:
      throw new Error(`Action type ${type} is undefined`);
  }
};

export { AccountReducer, initialAccountState };
