import axios from "axios";
import Cookies from "js-cookie";

import { refreshToken } from "./auth";

const callApi = async (
  {
    body,
    query,
    endpoint,
    method = "GET",
  }: {
    body?: any;
    query?: any;
    endpoint: string;
    method?: "GET" | "PUT" | "POST" | "PATCH" | "DELETE";
  },
  isRetry = true
) => {
  const token = Cookies.get("accessTokenEmp");

  const options: any = {
    method,
    url: endpoint,
    timeout: 60 * 1000,
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: { "Content-Type": "application/json" },
  };

  if (body) options.data = body;
  if (query) options.params = query;
  if (token) options.headers = { ...options.headers, Authorization: token };

  const res = await axios(options).catch((err) => {
    if (err.response) {
      if (isRetry && err.response.status === 401) {
        refreshToken();
      }

      if (err.response.status === 500) {
        throw new Error("Internal server error");
      }

      throw new Error(err.response.data.message);
    } else {
      if (err.request) {
        throw new Error("Can't send request to server");
      } else {
        throw new Error(err.message);
      }
    }
  });

  return res.data;
};

export default callApi;
